// Fonts
$font-family-sans-serif: 'Lato', sans-serif;
$body-font-family: $font-family-sans-serif;
$header-font-family: $font-family-sans-serif;

// Colours
$primary-color: rgb(229,0,125);
$primary-color-faded: rgba(229,0,125,0.5);
$secondary-color: rgb(0,158,227);

// Navigation specific variables
$nav_icon--color: #000;
$nav_icon--color--hover: lighten($nav_icon--color, 30%);
$nav_icon--color--active: lighten($nav_icon--color, 100%);

$nav_overlay--color: rgba(51,51,51,0.6);
$nav_link--color: #fff;
$nav_link--color--hover: darken($nav_link--color, 40%);

$nav_link--height: 7.5rem;
$nav_link--font-size: 3rem;
$nav_link--border: none;

// Animation helpers
$transition-time: 0.2s;
$transition-time--long: 0.4s;
$transition-time--extra-long: 2s;

$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.645, 0.045, 0.355, 1);

// Override default row widths
$row-width: rem-calc(100%);